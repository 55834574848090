import { Link, graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import React from "react"
import Seo from "../components/seo"
import SimplePage from "../components/SimplePage"

const SitemapPage = () => {
  const data = useStaticQuery(graphql`
  query {
    allSitePage(sort: {order: ASC, fields: path}) {
      nodes {
        path
      }
    }
  }
  `);

  return (
    <Layout >
      <Seo title="Sitemap" />

      <SimplePage>
      <h1 className="page-heading md:text-center mb-5 md:mb-10">Sitemap</h1>
      
      <div className="ele-style">
        <ul>
          {data.allSitePage.nodes.map((x) => {

            const path = x.path;

            if (path.includes('404') || path.includes('/success')) {
              return null;
            }

            if (path === '/sitemap') {
              return <li>{path}</li>;
            }
            
            return <li><Link to={path}>{path}</Link></li>;
          })}
        </ul>
      </div>
      </SimplePage>
    </Layout>
  )
}

export default SitemapPage